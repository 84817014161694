<template>
  <svg
    viewBox="0 0 44 44"
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.1158 5.05593C29.1499 4.94677 28.1677 4.97841 27.2004 5.15465C25.2006 5.51903 23.3592 6.48423 21.9219 7.92162C20.4845 9.35901 19.5193 11.2003 19.1549 13.2002C18.7905 15.2 19.0443 17.2634 19.8823 19.1154C20.2255 19.8738 20.0631 20.7655 19.4744 21.3542L5.65441 35.1742C5.23384 35.5947 4.99756 36.1652 4.99756 36.76C4.99756 37.3547 5.23384 37.9252 5.65441 38.3457C6.07499 38.7663 6.64541 39.0026 7.2402 39.0026C7.83498 39.0026 8.40541 38.7663 8.82599 38.3457L22.646 24.5257C23.2346 23.9371 24.1263 23.7746 24.8847 24.1178C26.7367 24.9559 28.8001 25.2096 30.8 24.8453C32.7998 24.4809 34.6412 23.5157 36.0785 22.0783C37.5159 20.6409 38.4811 18.7996 38.8455 16.7997C39.0218 15.8324 39.0534 14.8503 38.9442 13.8844L33.8003 19.0283C33.0526 19.7612 32.0472 20.1717 31.0002 20.1717C29.9532 20.1717 28.9479 19.7612 28.2002 19.0282L28.1859 19.0142L24.9718 15.8C24.2389 15.0523 23.8285 14.047 23.8285 13C23.8285 11.9529 24.239 10.9477 24.9719 10.2L24.9859 10.1857L30.1158 5.05593ZM26.4834 1.21944C29.2832 0.70931 32.172 1.06456 34.7647 2.23784C35.3629 2.50852 35.7901 3.05554 35.9078 3.70146C36.0255 4.34738 35.8187 5.00992 35.3544 5.47417L27.8286 13L31.0002 16.1715L38.526 8.64575C38.9902 8.1815 39.6528 7.97467 40.2987 8.09235C40.9446 8.21004 41.4916 8.63726 41.7623 9.23542C42.9356 11.8282 43.2909 14.717 42.7807 17.5168C42.2706 20.3165 40.9193 22.8944 38.907 24.9067C36.8946 26.9191 34.3168 28.2704 31.517 28.7805C29.1821 29.2059 26.7853 29.0295 24.5499 28.2787L11.6544 41.1742C10.4837 42.3449 8.89585 43.0026 7.2402 43.0026C5.58455 43.0026 3.99671 42.3449 2.82599 41.1742C1.65526 40.0034 0.997559 38.4156 0.997559 36.76C0.997559 35.1043 1.65526 33.5165 2.82599 32.3457L15.7215 19.4502C14.9707 17.2149 14.7943 14.8181 15.2197 12.4832C15.7298 9.68338 17.0811 7.10554 19.0934 5.09319C21.1058 3.08084 23.6836 1.72957 26.4834 1.21944Z"
    />
  </svg>
</template>
